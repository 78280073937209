import React from "react"
import { Container } from "react-bootstrap"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Features } from "../sections"

const ConsultoriaPage = () => (
  <Layout>
    <SEO title="Consultoria" />
    <Container>
      <Features />
    </Container>
  </Layout>
)

export default ConsultoriaPage
